import React from "react"
import './letreiro.scss'
import anime from 'animejs';

export class Letreiro extends React.Component<any, {indice:number}>{

    protected ref_texto:React.RefObject<HTMLDivElement>
    protected height:number = 0
    protected esta_animado:boolean = false

    constructor(public props:{children:Array<React.ReactElement>}) {
        super(props)
        this.ref_texto = React.createRef()
        this.state = {
            'indice': 0
        }
    }

    animar(){

        if( this.esta_animado ){
            return
        }
        
        this.esta_animado = true
        this.criaAnimacao()

    }

    protected criaAnimacao(){

        anime
            .timeline({
                loop:false,
                'complete':() => this.criaAnimacao(),
            })
            .add({
                'targets': this.ref_texto.current,
                'delay':800,
                'duration':900,
                'translateY':this.height * -1,
                'easing': 'easeInQuart',
                'complete':() => this.moveProximoIndice(),
            })
            .add({
                'targets': this.ref_texto.current,
                'duration':400,
                'translateY':[this.height, 0],
                'endDelay':400,
                'easing': 'easeOutCirc',
            })

    }

    moveProximoIndice(){

        let proximoIndice = this.state.indice + 1

        if( proximoIndice >= this.props.children.length ){
            proximoIndice = 0
        }

        this.setState({
            indice: proximoIndice
        })

    }

    componentDidMount(){
        this.height = this.ref_texto.current!.clientHeight
    }

    render(){

        return (
            <div className="letreiro">
                <div ref={this.ref_texto} className="texto">
                    {this.props.children[this.state.indice]}
                </div>
            </div>
        )

    }

}