import React from "react"
import './mark-text.scss'

export class MarkText extends React.Component<any, {highlight:boolean}>{

    constructor(public props:{children:string, delay?:number}) {
        super(props)
        this.state = {highlight:false}
    }

    componentDidMount(){

    }

    highlight(){

        console.log('hightlight')

        setTimeout(() => {
            this.setState({highlight:true})
        }, this.props.delay ?? 0)

    }

    render(){
        return (
            <mark className={this.state.highlight ? 'highlight is-mark' : 'is-mark'}>{this.props.children}</mark>
        )
    }

}