import React from "react"
import { MarkText } from "../../../../../../components/html/mark-text/mark-text"
import ScrollMagic from 'scrollmagic'

export class ScreenMetodoComoTrabalhamosComponent extends React.Component{

    protected ref_page:React.RefObject<HTMLDivElement>
    protected scrollController:ScrollMagic.Controller
    protected ref_mark1:React.RefObject<MarkText>
    protected ref_mark2:React.RefObject<MarkText>
    protected ref_mark3:React.RefObject<MarkText>
    protected ref_mark4:React.RefObject<MarkText>


    constructor(public props:any){
        super(props)
        this.ref_mark1 = React.createRef()
        this.ref_mark2 = React.createRef()
        this.ref_mark3 = React.createRef()
        this.ref_mark4 = React.createRef()
        this.ref_page = React.createRef()
        this.scrollController = new ScrollMagic.Controller()
    }

    componentDidMount(){

        new ScrollMagic.Scene({
            'triggerElement':this.ref_page.current, 
            'duration':this.ref_page.current!.clientHeight
        }).on("enter leave", (e:ScrollMagic.SceneProgressEvent<ScrollMagic.EventType>) => {
            
            if(e.type === 'enter'){
                this.highlight()
            }

        })
        .addTo(this.scrollController);

    }

    highlight(){
        this.ref_mark1.current?.highlight()
        this.ref_mark2.current?.highlight()
        this.ref_mark3.current?.highlight()
        this.ref_mark4.current?.highlight()
    }

    render(): React.ReactNode {

        return <>
            <div ref={this.ref_page} className="metodo-intro-texto">
                <p>
                    A nossa metodologia se baseia na idéia de people-centered design, ou seja, desenvolvemos soluções 
                    pensando nas necessidades das pessoas que irão utilizá-las.            
                </p>
                <p>
                    Nos últimos 10 anos, vivemos uma verdadeira revolução digital, que trouxe muitas facilidades 
                    e opções de websites, sistemas e projetos para serem adotados em negócios. 
                    No entanto, muitas dessas soluções não foram desenvolvidas considerando as suas necessidades ou objetivos.
                </p>
                <p>
                    Por isso, oferecemos o serviço de criação de softwares 
                    <MarkText ref={this.ref_mark1} delay={1000}>sob medida</MarkText>, garantindo &nbsp;
                    <MarkText ref={this.ref_mark2} delay={2000}>entrega no prazo</MarkText> e &nbsp;
                    <MarkText ref={this.ref_mark3} delay={3000}>dentro do orçamento</MarkText>, &nbsp;
                    sempre levando o <MarkText ref={this.ref_mark4} delay={4000}>seu negócio</MarkText> em consideração.                    
                </p>

            </div>
        </>
    }

}